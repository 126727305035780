<template>
    <div>
        <SelectInput v-model:value="field_position" :options="field_position_options" v-model:errors="errors.field_position" :label="$t('register.profession.field_position')"  id="field_position" />
        <TextInput v-model:value="expertise" v-model:errors="errors.expertise" :label="$t('profile.expertise.title')" :placeholder="$t('profile.expertise.placeholder')" class="pt-5" />
    </div>
</template>

<script>
import TextInput from "@/components/forms/TextInput";
import SelectInput from "@/components/forms/SelectInput";
import { walkthrough_to_store } from "@/helper/store/walkthrough_to_storage";
import { register_validation } from "@/helper/api/RegistrationHelper";
import { getSportPositions } from "@/helper/api/SportHelper";
import { setLoading } from "@/helper/store/loadingSpinnerHelper";
import messages from "@/helper/store/messageHelper";

// ToDo: load sport positions from endpoint.

export default {
    name: "ProfessionStep",
    created(){
        this.loadSportPositions();
    },
    methods:{
         validate: async function (){
            setLoading(true);

            const data = {
                field_position: this.field_position, 
                expertise: this.expertise, 
            }

            const result = await register_validation(data)
                .catch((err) => {
                    messages.error(err.message);
                    return false;
                })
                .finally(() => {
                    setLoading(false);
                });

            // console.log('result', result)

            if (result.success){
                return true;
            } else {
                this.errors = result.errors;
                return false;
            }
        },
        loadSportPositions(){
            getSportPositions()
                .then(positions => {
                    this.field_position_options = positions.map(pos => {return { key: pos.abbreviation, text: pos.name }});
                })
                .catch(err => console.log(err));
        },
    },
    async beforeRouteLeave (to, from, next) {        
        if (to.meta.number > from.meta.number && ! await this.validate()){
            next(false);
        } else {
            next();
        }
    },
    components:{
        SelectInput,
        TextInput
    }, 
    computed: {
        field_position: walkthrough_to_store('field_position'),
        expertise: walkthrough_to_store('expertise'),
    },
    data(){
        return {
            errors: {
                field_position: [], 
                expertise: [],
            },
            field_position_options: [],
        }
    },
}
</script>

<style scoped>

</style>
